import React from 'react';
import '../../App.css';
import './About.css';
import Footer from '../Footer';
import InvoiceData from '../InvoiceData';
import { useTranslation } from "react-i18next";

export default function About() {

  const { t } = useTranslation();

  return <div className="about-container">
    <div className="about-form">
      <h1 >{t('title.about')}</h1>
      <br></br>
      <p>{t('text.about1')}</p>
      <br></br>
      <p>{t('text.about2')}</p>
      <br></br>
      <p>{t('text.about3')}</p>
      <br></br>
      <p>{t('text.about4')}</p>
    </div>
    
    <Footer />
  </div>
}
